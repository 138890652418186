<template>
  <div v-touch:hold="removeItem" class="place-details">
    <div v-if="isLoading" style="text-align: center; margin-top: 10px">
      Ładowanie...
    </div>
    <div v-if="data" class="place-details__body">
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 3px;
          align-items: center;
        "
        class=""
      >
        <span class="place-details__short-name">
          {{
            data.short_name && data.short_name !== ""
              ? data.short_name
              : data.name
          }}</span
        >
      </div>
      <div
        style="cursor: pointer; display: flex; align-items: center; gap: 10px"
        class=""
      >
        <!-- <svg
          @click="removeItem"
          style="cursor: pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-trash"
        >
          <polyline points="3 6 5 6 21 6"></polyline>
          <path
            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
          ></path>
        </svg> -->
        <svg
          @click="handleRedirectToPanel"
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 2.91669C9.47913 2.91669 2.91663 9.47919 2.91663 17.5C2.91663 25.5209 9.47913 32.0834 17.5 32.0834C25.5208 32.0834 32.0833 25.5209 32.0833 17.5C32.0833 9.47919 25.5208 2.91669 17.5 2.91669ZM22.8958 18.5209L18.5208 22.8959C17.9375 23.4792 17.0625 23.4792 16.4791 22.8959C15.8958 22.3125 15.8958 21.4375 16.4791 20.8542L18.375 18.9584H13.125C12.25 18.9584 11.6666 18.375 11.6666 17.5C11.6666 16.625 12.25 16.0417 13.125 16.0417H18.375L16.4791 14.1459C15.8958 13.5625 15.8958 12.6875 16.4791 12.1042C17.0625 11.5209 17.9375 11.5209 18.5208 12.1042L22.8958 16.4792C23.4791 17.0625 23.4791 17.9375 22.8958 18.5209Z"
            fill="#337AB7"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGetPlaceDetailsQuery } from "@/api/places";
import AppButton from "@/components/AppButton.vue";
import { onMounted, ref, defineProps } from "vue";
const props = defineProps<{
  panel: string;
  enabled: boolean;
}>();

const { data, isLoading, error, isError } = useGetPlaceDetailsQuery(
  props.panel ?? "",
  props.enabled
);

const handleRedirectToPanel = () => {
  window.open(`https://${props.panel}.stoperek.pl/`);
};

const removeItem = () => {
  const response = confirm("Czy na pewno chcesz usunąć placówkę?");
  if (response) {
    const codesFromLS = localStorage.getItem("stoperekpl_codes") ?? "";
    const codesFromLsParsed = JSON.parse(codesFromLS);
    const fullArray = [
      ...new Set(codesFromLsParsed.filter((el) => el !== props.panel)),
    ];
    localStorage.setItem("stoperekpl_codes", JSON.stringify(fullArray));
    window.location.reload();
  }
};
</script>
<style scoped lang="scss">
.place-details {
  cursor: pointer;
  &__body {
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 30px;
    padding: 5px 0;
    border-bottom: 1px solid #337ab7;
  }
  &__short-name {
    font-size: 15px;
    font-weight: 400;
    color: #000000;
  }
}
</style>
