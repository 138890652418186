<!-- PWAPrompt.vue -->
<template>
  <div
    style="
      margin: 30px auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      padding: 10px;
      border-radius: 20px;
      width: 330px;
      height: 200px;

      background: #fff;
      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    "
    v-if="shown"
  >
    <span style="font-size: 18px">Zainstaluj naszą darmową aplikację.</span>

    <AppButton
      @click="installPWA"
      style="max-width: 200px; margin-top: 20px; margin-bottom: 10px"
      :text="' Instaluj'"
      :plus="true"
    />
    <div
      style="
        font-weight: 700;
        opacity: 50%;
        text-decoration: underline;
        cursor: pointer;
      "
      @click="dismissPrompt"
    >
      Nie, dziękuję
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, inject } from "vue";
import AppButton from "@/components/AppButton.vue";
const emitter = inject("emitter");

const shown = ref(false);
let installEvent = null;

emitter?.on("showPopup", (e) => {
  setTimeout(() => {
    window.location.reload();
  }, 500);
});

const dismissPrompt = () => {
  shown.value = false;
};

const installPWA = () => {
  if (installEvent) {
    installEvent.prompt();
    installEvent.userChoice.then((choice) => {
      dismissPrompt();
      if (choice.outcome === "accepted") {
        // Do something additional if the user chose to install
      } else {
        // Do something additional if the user declined
      }
    });
  }
};

onBeforeMount(() => {
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    installEvent = e;
    shown.value = true;
  });
});
</script>
