import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MainLayout from "@/layouts/MainLayout.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HelpView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "address" }, [
        _createTextVNode(" Stoperek.pl "),
        _createElementVNode("br"),
        _createTextVNode(" 15-440 Białystok "),
        _createElementVNode("br"),
        _createTextVNode("ul. Icchoka Malmeda 13/1 ")
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", {
        style: {"margin-top":"40px"},
        class: "help"
      }, [
        _createElementVNode("span", { style: {"font-weight":"800"} }, " Wsparcie i pomoc"),
        _createElementVNode("br"),
        _createElementVNode("span", { style: {} }, [
          _createElementVNode("a", {
            style: {"color":"black","cursor":"pointer","text-decoration":"underline"},
            href: "mailto:pomoc@stoperek.pl"
          }, "pomoc@stoperek.pl"),
          _createElementVNode("br"),
          _createElementVNode("a", {
            style: {"color":"black","cursor":"pointer","text-decoration":"underline"},
            href: "https://www.stoperek.pl"
          }, "www.stoperek.pl")
        ])
      ], -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", {
        style: {"margin-top":"40px"},
        class: "help"
      }, [
        _createElementVNode("span", { style: {"font-weight":"800"} }, " Biuro "),
        _createElementVNode("br"),
        _createElementVNode("span", null, [
          _createElementVNode("a", {
            style: {"color":"black","text-decoration":"underline","cursor":"pointer"},
            href: "mailto:biuro@stoperek.pl"
          }, "biuro@stoperek.pl"),
          _createElementVNode("br")
        ])
      ], -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", {
        style: {"margin-top":"40px"},
        class: "help"
      }, [
        _createElementVNode("span", { style: {"font-weight":"800"} }, " Sprzedaż i zamówienia "),
        _createElementVNode("br"),
        _createElementVNode("span", null, [
          _createElementVNode("a", {
            style: {"color":"black","text-decoration":"underline","cursor":"pointer"},
            href: "mailto:sprzedaz@stoperek.pl"
          }, "sprzedaz@stoperek.pl"),
          _createElementVNode("br")
        ])
      ], -1)),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
        style: {"margin-left":"auto","width":"fit-content","cursor":"pointer","position":"fixed","bottom":"10px","right":"10px"},
        class: ""
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("svg", {
          width: "74",
          height: "76",
          viewBox: "0 0 74 76",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("g", { filter: "url(#filter0_d_801_421)" }, [
            _createElementVNode("circle", {
              cx: "38",
              cy: "38",
              r: "28",
              fill: "#337AB7"
            })
          ]),
          _createElementVNode("path", {
            d: "M45 38H31M31 38L36.25 33M31 38L36.25 43",
            stroke: "#EAEAEA",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }),
          _createElementVNode("defs", null, [
            _createElementVNode("filter", {
              id: "filter0_d_801_421",
              x: "0",
              y: "0",
              width: "76",
              height: "76",
              filterUnits: "userSpaceOnUse",
              "color-interpolation-filters": "sRGB"
            }, [
              _createElementVNode("feFlood", {
                "flood-opacity": "0",
                result: "BackgroundImageFix"
              }),
              _createElementVNode("feColorMatrix", {
                in: "SourceAlpha",
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                result: "hardAlpha"
              }),
              _createElementVNode("feOffset"),
              _createElementVNode("feGaussianBlur", { stdDeviation: "5" }),
              _createElementVNode("feComposite", {
                in2: "hardAlpha",
                operator: "out"
              }),
              _createElementVNode("feColorMatrix", {
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              }),
              _createElementVNode("feBlend", {
                mode: "normal",
                in2: "BackgroundImageFix",
                result: "effect1_dropShadow_801_421"
              }),
              _createElementVNode("feBlend", {
                mode: "normal",
                in: "SourceGraphic",
                in2: "effect1_dropShadow_801_421",
                result: "shape"
              })
            ])
          ])
        ], -1)
      ]))
    ]),
    _: 1
  }))
}
}

})