import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import RedeemCodeView from "@/views/RedeemCodeView.vue";
import HelpView from "@/views/HelpView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/redeem-code",
    name: "redeem-code",
    component: RedeemCodeView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
