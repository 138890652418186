<template>
  <div class="layout">
    <header class="layout__header">
      <img src="/img/logo.png" alt="Logo" class="layout__header__logo" />
      <div class="layout__header__line"></div>
    </header>

    <main class="layout__content">
      <div v-if="!isOnline" class="layout__offline">
        <svg
          fill="#000000"
          width="100px"
          height="100px"
          viewBox="0 0 36 36"
          version="1.1"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>no-wifi-line</title>
          <path
            class="clr-i-outline clr-i-outline-path-1"
            d="M18,24.42a4,4,0,1,0,4,4A4,4,0,0,0,18,24.42Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,18,30.42Z"
          ></path>
          <path
            class="clr-i-outline clr-i-outline-path-2"
            d="M26.21,21.85a1,1,0,0,0-.23-1.4,13.56,13.56,0,0,0-5-2.23l3.87,3.87A1,1,0,0,0,26.21,21.85Z"
          ></path>
          <path
            class="clr-i-outline clr-i-outline-path-3"
            d="M18.05,10.72a20.88,20.88,0,0,0-4.16.43l1.74,1.74a19,19,0,0,1,2.42-.17A18.76,18.76,0,0,1,28.64,16a1,1,0,0,0,1.12-1.65A20.75,20.75,0,0,0,18.05,10.72Z"
          ></path>
          <path
            class="clr-i-outline clr-i-outline-path-4"
            d="M33.55,8.2A28.11,28.11,0,0,0,8.11,5.36L9.69,6.93A26,26,0,0,1,32.45,9.87a1,1,0,0,0,1.1-1.67Z"
          ></path>
          <path
            class="clr-i-outline clr-i-outline-path-5"
            d="M1.84,4.75,4.27,7.18c-.62.34-1.23.7-1.83,1.1A1,1,0,1,0,3.56,9.94C4.26,9.47,5,9,5.74,8.65l3.87,3.87A20.59,20.59,0,0,0,6.23,14.4,1,1,0,0,0,7.36,16a18.82,18.82,0,0,1,3.77-2l4.16,4.16A13.51,13.51,0,0,0,10,20.55a1,1,0,0,0,1.18,1.61A11.52,11.52,0,0,1,17,20l10.8,10.8,1.41-1.41-26-26Z"
          ></path>
          <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
        </svg>
        Brak połączenia z internetem. <br />
        Aby korzystać z aplikacji, musisz mieć połączenie z internetem.
      </div>
      <div v-else>
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const isOnline = ref(navigator.onLine);

const updateOnlineStatus = () => {
  isOnline.value = navigator.onLine;
};

onMounted(() => {
  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);
});

onBeforeUnmount(() => {
  window.removeEventListener("online", updateOnlineStatus);
  window.removeEventListener("offline", updateOnlineStatus);
});
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__offline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    margin-top: 100px;
    padding: 0 30px;
  }

  &__header {
    background-color: #fff;
    padding: 20px;
    text-align: center;

    &__line {
      height: 1px;
      width: 100%;
      background: #337ab7;
      margin-top: 10px;
    }
    &__logo {
      min-width: 315px;
      height: auto;
    }
  }
  &__content {
    flex: 1;
    padding: 0px;
  }
}
</style>
