<template>
  <div class="places-list">
    <div v-if="codes.length === 0" class="places-list__missing">
      Nie masz jeszcze wprowadzonej żadnej placówki
      <div @click="$router.push('/help')" class="places-list__list__help">
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_801_430)">
            <circle cx="38" cy="38" r="28" fill="#337AB7" />
          </g>
          <path
            d="M36.3452 43.4545V43.3182C36.3603 41.8712 36.5118 40.7197 36.7997 39.8636C37.0876 39.0076 37.4967 38.3144 38.027 37.7841C38.5573 37.2538 39.1937 36.7652 39.9361 36.3182C40.383 36.0455 40.7846 35.7235 41.1406 35.3523C41.4967 34.9735 41.777 34.5379 41.9815 34.0455C42.1937 33.553 42.2997 33.0076 42.2997 32.4091C42.2997 31.6667 42.1255 31.0227 41.777 30.4773C41.4285 29.9318 40.9626 29.5114 40.3793 29.2159C39.7959 28.9205 39.1482 28.7727 38.4361 28.7727C37.8149 28.7727 37.2164 28.9015 36.6406 29.1591C36.0649 29.4167 35.5838 29.822 35.1974 30.375C34.8111 30.928 34.5876 31.6515 34.527 32.5455H31.6634C31.724 31.2576 32.0573 30.1553 32.6634 29.2386C33.277 28.322 34.0838 27.6212 35.0838 27.1364C36.0914 26.6515 37.2088 26.4091 38.4361 26.4091C39.7694 26.4091 40.9285 26.6742 41.9134 27.2045C42.9058 27.7348 43.6709 28.4621 44.2088 29.3864C44.7543 30.3106 45.027 31.3636 45.027 32.5455C45.027 33.3788 44.8982 34.1326 44.6406 34.8068C44.3906 35.4811 44.027 36.0833 43.5497 36.6136C43.08 37.1439 42.5118 37.6136 41.8452 38.0227C41.1785 38.4394 40.6444 38.8788 40.2429 39.3409C39.8414 39.7955 39.5497 40.3371 39.3679 40.9659C39.1861 41.5947 39.0876 42.3788 39.0724 43.3182V43.4545H36.3452ZM37.7997 50.1818C37.2391 50.1818 36.758 49.9811 36.3565 49.5795C35.955 49.178 35.7543 48.697 35.7543 48.1364C35.7543 47.5758 35.955 47.0947 36.3565 46.6932C36.758 46.2917 37.2391 46.0909 37.7997 46.0909C38.3603 46.0909 38.8414 46.2917 39.2429 46.6932C39.6444 47.0947 39.8452 47.5758 39.8452 48.1364C39.8452 48.5076 39.7505 48.8485 39.5611 49.1591C39.3793 49.4697 39.133 49.7197 38.8224 49.9091C38.5194 50.0909 38.1785 50.1818 37.7997 50.1818Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_801_430"
              x="0"
              y="0"
              width="76"
              height="76"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_801_430"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_801_430"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
    <div v-if="codes.length > 0" class="places-list__list">
      <div class="places-list__list__title">Wybierz Placówkę</div>
      <PlaceItem
        v-for="code in codes"
        :panel="code"
        :key="code"
        :enabled="true"
      />
      <div @click="$router.push('/help')" class="places-list__list__help">
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_801_430)">
            <circle cx="38" cy="38" r="28" fill="#337AB7" />
          </g>
          <path
            d="M36.3452 43.4545V43.3182C36.3603 41.8712 36.5118 40.7197 36.7997 39.8636C37.0876 39.0076 37.4967 38.3144 38.027 37.7841C38.5573 37.2538 39.1937 36.7652 39.9361 36.3182C40.383 36.0455 40.7846 35.7235 41.1406 35.3523C41.4967 34.9735 41.777 34.5379 41.9815 34.0455C42.1937 33.553 42.2997 33.0076 42.2997 32.4091C42.2997 31.6667 42.1255 31.0227 41.777 30.4773C41.4285 29.9318 40.9626 29.5114 40.3793 29.2159C39.7959 28.9205 39.1482 28.7727 38.4361 28.7727C37.8149 28.7727 37.2164 28.9015 36.6406 29.1591C36.0649 29.4167 35.5838 29.822 35.1974 30.375C34.8111 30.928 34.5876 31.6515 34.527 32.5455H31.6634C31.724 31.2576 32.0573 30.1553 32.6634 29.2386C33.277 28.322 34.0838 27.6212 35.0838 27.1364C36.0914 26.6515 37.2088 26.4091 38.4361 26.4091C39.7694 26.4091 40.9285 26.6742 41.9134 27.2045C42.9058 27.7348 43.6709 28.4621 44.2088 29.3864C44.7543 30.3106 45.027 31.3636 45.027 32.5455C45.027 33.3788 44.8982 34.1326 44.6406 34.8068C44.3906 35.4811 44.027 36.0833 43.5497 36.6136C43.08 37.1439 42.5118 37.6136 41.8452 38.0227C41.1785 38.4394 40.6444 38.8788 40.2429 39.3409C39.8414 39.7955 39.5497 40.3371 39.3679 40.9659C39.1861 41.5947 39.0876 42.3788 39.0724 43.3182V43.4545H36.3452ZM37.7997 50.1818C37.2391 50.1818 36.758 49.9811 36.3565 49.5795C35.955 49.178 35.7543 48.697 35.7543 48.1364C35.7543 47.5758 35.955 47.0947 36.3565 46.6932C36.758 46.2917 37.2391 46.0909 37.7997 46.0909C38.3603 46.0909 38.8414 46.2917 39.2429 46.6932C39.6444 47.0947 39.8452 47.5758 39.8452 48.1364C39.8452 48.5076 39.7505 48.8485 39.5611 49.1591C39.3793 49.4697 39.133 49.7197 38.8224 49.9091C38.5194 50.0909 38.1785 50.1818 37.7997 50.1818Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_801_430"
              x="0"
              y="0"
              width="76"
              height="76"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_801_430"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_801_430"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div
        @click="$router.push('/redeem-code')"
        class="places-list__list__button"
      >
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_47_45)">
            <circle cx="38" cy="38" r="28" fill="#F4F5F8" />
          </g>
          <path
            d="M38 31V38M38 38V45M38 38H31M38 38H45"
            stroke="#222222"
            stroke-width="2"
            stroke-linecap="round"
          />
          <defs>
            <filter
              id="filter0_d_47_45"
              x="0"
              y="0"
              width="76"
              height="76"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_47_45"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_47_45"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
    <AppButton
      @click="$router.push('/redeem-code')"
      v-if="codes.length === 0"
      style="max-width: 400px; margin: auto; margin-top: 50px"
      :text="'Dodaj placówkę'"
      :plus="true"
    />
  </div>
</template>

<script lang="ts" setup>
import AppButton from "@/components/AppButton.vue";
import PlaceItem from "@/components/PlaceItem.vue";
import { onMounted, ref } from "vue";

const codes = ref([]);

onMounted(() => {
  const codesFromLS = localStorage.getItem("stoperekpl_codes") ?? "";
  if (!codesFromLS) {
    codes.value = [];
    return;
  }
  const codesFromLsParsed = JSON.parse(codesFromLS);
  if (!codesFromLsParsed || codesFromLsParsed.length === 0) {
    codes.value = [];
    return;
  }
  codes.value = codesFromLsParsed;
});
</script>
<style scoped lang="scss">
.places-list {
  display: flex;
  flex-direction: column;
  &__list {
    padding: 0 20px;
    padding-bottom: 100px;
    &__title {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      max-width: 140px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    &__button {
      position: fixed;
      bottom: 10px;
      right: 10px;
      cursor: pointer;
    }
    &__help {
      position: fixed;
      bottom: 10px;
      left: 10px;
      cursor: pointer;
    }
  }
  &__missing {
    font-size: 16px;
    font-weight: 400;
    padding: 0 40px;
    color: #000000;
    text-align: center;
  }
}
</style>
