<template>
  <div class="place-details">
    <div v-if="isLoading" style="text-align: center; margin-top: 10px">
      Ładowanie...
    </div>
    <div v-if="data" class="place-details__body">
      <div style="display: flex; flex-direction: column" class="">
        <span class="place-details__short-name">
          {{
            data.short_name && data.short_name !== ""
              ? data.short_name
              : data.name
          }}</span
        >
        <span class="place-details__name">{{ data.name }}</span>
      </div>
      <div @click="handleAddNewPlace" style="cursor: pointer" class="">
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35 5.83334C18.9583 5.83334 5.83331 18.9583 5.83331 35C5.83331 51.0417 18.9583 64.1667 35 64.1667C51.0417 64.1667 64.1667 51.0417 64.1667 35C64.1667 18.9583 51.0417 5.83334 35 5.83334ZM45.7916 37.0417L37.0416 45.7917C35.875 46.9583 34.125 46.9583 32.9583 45.7917C31.7916 44.625 31.7916 42.875 32.9583 41.7083L36.75 37.9167H26.25C24.5 37.9167 23.3333 36.75 23.3333 35C23.3333 33.25 24.5 32.0833 26.25 32.0833H36.75L32.9583 28.2917C31.7916 27.125 31.7916 25.375 32.9583 24.2083C34.125 23.0417 35.875 23.0417 37.0416 24.2083L45.7916 32.9583C46.9583 34.125 46.9583 35.875 45.7916 37.0417Z"
            fill="#FF7C03"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGetPlaceDetailsQuery } from "@/api/places";
import AppButton from "@/components/AppButton.vue";
import router from "@/router";
import { onMounted, ref, defineProps, inject } from "vue";

const emitter = inject("emitter");

const props = defineProps<{
  panel: string;
  enabled: boolean;
}>();
const { data, isLoading, error, isError } = useGetPlaceDetailsQuery(
  props.panel ?? "",
  props.enabled
);

const handleAddNewPlace = () => {
  const codesFromLS = localStorage.getItem("stoperekpl_codes") ?? "";
  if (!codesFromLS) {
    localStorage.setItem("stoperekpl_codes", JSON.stringify([props.panel]));
  } else {
    const codesFromLsParsed = JSON.parse(codesFromLS);
    const fullArray = [...new Set([...codesFromLsParsed, props.panel])];
    localStorage.setItem("stoperekpl_codes", JSON.stringify(fullArray));
  }
  router.push("/");
  emitter.emit("showPopup");
};
</script>
<style scoped lang="scss">
.place-details {
  &__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  &__short-name {
    font-size: 16px;
    font-weight: 600;
    color: #337ab7;
  }
  &__name {
    font-size: 10px;
    font-weight: 400;
    margin-top: 8px;
  }
}
</style>
