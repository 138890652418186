import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "app-button" }
const _hoisted_2 = {
  key: 0,
  class: "plus-icon"
}



// Definiowanie props

export default /*@__PURE__*/_defineComponent({
  __name: 'AppButton',
  props: {
    text: {},
    plus: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (_ctx.plus)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "+"))
      : _createCommentVNode("", true)
  ]))
}
}

})