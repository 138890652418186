import { useQuery } from "@tanstack/vue-query";
export interface Code {
  panel: string;
  kod: number;
  nazwa: string;
}
export interface Place {
  name: string;
  short_name: string;
}

export async function getCodes(): Promise<Code[]> {
  const res = await fetch("https://bazy.stoperek.pl/kody.json");
  const codes = await res.json();
  return codes as Code[];
}

export async function getPlaceDetails(name: string): Promise<Place | null> {
  const res = await fetch(`https://${name}.stoperek.pl/api/info`);
  const data = await res.json();
  return data;
}

export const useGetCodesQuery = () => {
  const query = useQuery({
    queryKey: ["place-codes"],
    queryFn: () => getCodes(),
  });
  return query;
};
export const useGetPlaceDetailsQuery = (name: string, enabled?: boolean) => {
  const query = useQuery({
    queryKey: ["place-details", name],
    queryFn: () => getPlaceDetails(name),
    enabled: enabled ? true : false,
  });
  return query;
};
