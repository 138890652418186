<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
  <!-- <div
    id="custom-splash"
    style="
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fecc00;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <img
      src="../public/img/icons/android-chrome-512x512.png"
      alt="Logo"
      style="width: 128px; height: 128px"
    />
  </div> -->
</template>

<script>
// window.addEventListener("load", function () {
//   const splash = document.getElementById("custom-splash");
//   splash.style.display = "none";
// });
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
#app {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.code-field__list {
  display: flex !important;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.code-field__input {
  height: 42px !important;
  max-width: 41px !important;
  margin: auto;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #888888;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #fff;
  }
}
</style>
