import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "place-details" }
const _hoisted_2 = {
  key: 0,
  style: {"text-align":"center","margin-top":"10px"}
}
const _hoisted_3 = {
  key: 1,
  class: "place-details__body"
}
const _hoisted_4 = {
  style: {"display":"flex","flex-direction":"column"},
  class: ""
}
const _hoisted_5 = { class: "place-details__short-name" }
const _hoisted_6 = { class: "place-details__name" }

import { useGetPlaceDetailsQuery } from "@/api/places";
import AppButton from "@/components/AppButton.vue";
import router from "@/router";
import { onMounted, ref, inject } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaceDetails',
  props: {
    panel: {},
    enabled: { type: Boolean }
  },
  setup(__props: any) {

const emitter = inject("emitter");

const props = __props;
const { data, isLoading, error, isError } = useGetPlaceDetailsQuery(
  props.panel ?? "",
  props.enabled
);

const handleAddNewPlace = () => {
  const codesFromLS = localStorage.getItem("stoperekpl_codes") ?? "";
  if (!codesFromLS) {
    localStorage.setItem("stoperekpl_codes", JSON.stringify([props.panel]));
  } else {
    const codesFromLsParsed = JSON.parse(codesFromLS);
    const fullArray = [...new Set([...codesFromLsParsed, props.panel])];
    localStorage.setItem("stoperekpl_codes", JSON.stringify(fullArray));
  }
  router.push("/");
  emitter.emit("showPopup");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Ładowanie... "))
      : _createCommentVNode("", true),
    (_unref(data))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(data).short_name && _unref(data).short_name !== ""
              ? _unref(data).short_name
              : _unref(data).name), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(data).name), 1)
          ]),
          _createElementVNode("div", {
            onClick: handleAddNewPlace,
            style: {"cursor":"pointer"},
            class: ""
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("svg", {
              width: "70",
              height: "70",
              viewBox: "0 0 70 70",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M35 5.83334C18.9583 5.83334 5.83331 18.9583 5.83331 35C5.83331 51.0417 18.9583 64.1667 35 64.1667C51.0417 64.1667 64.1667 51.0417 64.1667 35C64.1667 18.9583 51.0417 5.83334 35 5.83334ZM45.7916 37.0417L37.0416 45.7917C35.875 46.9583 34.125 46.9583 32.9583 45.7917C31.7916 44.625 31.7916 42.875 32.9583 41.7083L36.75 37.9167H26.25C24.5 37.9167 23.3333 36.75 23.3333 35C23.3333 33.25 24.5 32.0833 26.25 32.0833H36.75L32.9583 28.2917C31.7916 27.125 31.7916 25.375 32.9583 24.2083C34.125 23.0417 35.875 23.0417 37.0416 24.2083L45.7916 32.9583C46.9583 34.125 46.9583 35.875 45.7916 37.0417Z",
                fill: "#FF7C03"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})